<template>
	<div>
		<div class='card'>
			<div class='card-body'>
				The election you are trying to view is invalid. Please check your link and try again.
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: 'NotFound'
}
</script>